<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('common.top_title', { nickname: myInfo.user_nickname })"></h1>
      <p>{{ $t('common.top_subtitle') }}</p>
    </div>
    <div class="smenu">
      <div class="mypage_sub_title">
        <a href="javascript:" @click="this.$router.back()">
          <img src="../../assets/img/btn_back.png">
        </a>{{ $t('more.refund') }}
      </div>
    </div>

    <div class="wrap">
      <div class="label" style="width: calc(100% - 40px);text-align: center;">{{ $t('refund.top_hint') }}</div>

      <div class="form">
        <div style="align-self: flex-end;margin-bottom: 20px;"><a href='javascript:' class="button" @click="onLoadPreviousInfo">
            過去の情報を読み込む
          </a></div>

        <div class="form_row">
          <span class="left_column label">{{ $t('refund.refund_14') }}</span>
          <input class="input_border" type="text" :placeholder="$t('refund.refund_03')" v-model="account_owner" />
        </div>

        <div class="form_row">
          <span class="left_column label">{{ $t('refund.refund_19') }}</span>
          <div class="input_border">
            <select v-model="bank" style="width: 100%;">
              <option value="">{{ $t('refund.refund_29') }}</option>
              <option v-for="bank in banks" :key="bank" :value="bank">{{ bank }}</option>
            </select>
          </div>
        </div>

        <div class="form_row">
          <span class="left_column label">{{ $t('refund.refund_20') }}</span>
          <input class="input_border" type="text" :placeholder="$t('refund.refund_08')" v-model="account_number" />
        </div>

        <div class="label" style="margin-top: 30px;text-align: center;margin-bottom: 20px;">{{ $t('refund.middle_hint')
          }}</div>
        <div class="form_row">
          <span style="color:#b6b6b6;font-size: 16px;font-weight: bold;margin-right: 10px;">{{ $t('refund.refund_13')
            }}</span>
          <span class="cash_value">{{ currency(myInfo.cash) }}</span>
          <img src="../../assets/img/bread.png" />
          <div class="input_border" style="margin-left: 20px;display:flex;flex-direction: row;align-items: center;">
            <input type="text"
              style="font-size: 16px;font-weight: bold;color: #f25c62;text-align:end;margin-right:10px;flex: 1;width:0px;"
              v-model="cash" />
            <img src="../../assets/img/bread.png" />
          </div>
        </div>

        <div class="form_row" style="justify-content: flex-end;">
          <span class="label" style="margin-right: 10px;">{{ $t('refund.refund_27') }}</span>
          <span class="cash_value">{{ currency(money) }}&nbsp;¥</span>
        </div>

        <div class="label" style="margin-top: 30px;text-align: center;margin-bottom: 30px;">{{ $t('refund.bottom_hint')
          }}</div>
      </div>

      <a href='javascript:' class="button" @click='onRequest()'>{{ $t('refund.refund_28') }}</a>

      <div class="clear"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./Refund.scss"></style>

<script>
import { requestPost, showToast, currency } from '../../lib/util';


export default {
  name: 'Refund',
  components: {
  },
  data() {
    let myInfo = this.$store.getters.me;

    return {
      myInfo: myInfo,
      account_owner: '',
      bank: '',
      account_number: '',
      cash: 0,
      banks: [
        '三井住友銀行',
        'みずほ銀行',
        '三菱UFJ銀行',
        'りそな銀行',
        '埼玉りそな銀行',
        'PayPay銀行',
        'ゆうちょ銀行',
        'smbc信託銀行',
      ],
    }
  },
  computed: {
    money() {
      return this.cash;
    },
  },
  methods: {
    onRequest: function () {
      if (this.account_owner == '') {
        showToast(this.$t('refund.refund_03'));
        return;
      }

      if (this.bank == '') {
        showToast(this.$t('refund.refund_29'));
        return;
      }

      if (this.account_number == '') {
        showToast(this.$t('refund.refund_08'));
        return;
      }

      if (Number.parseInt(this.cash) == 0) {
        showToast('申請するキャッシュを選択してください。');
        return;
      }

      if (Number.parseInt(this.cash) > this.myInfo.cash) {
        showToast(this.$t('refund.refund_11'));
        return;
      }

      let _app = this;
      requestPost('refund/requestRefund', {
        uid: this.myInfo.uid,
        name: this.account_owner,
        resident_number: '',
        phone_number: '-',
        address: '',
        bank: this.bank,
        account: this.account_number,
        payment: Number.parseInt(this.cash),
        refund_bonus: 0,
      },
        async response => {
          _app.myInfo.cash = _app.myInfo.cash - _app.cash;
          _app.$store.dispatch('setMyInfo', _app.myInfo);

          window.alert(_app.$t('refund.refund_12'));
          _app.$router.back();
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onLoadPreviousInfo: function () {
      let _app = this;
      requestPost('refund/getLastRefundInfo', {
        uid: this.myInfo.uid,
      },
        async response => {
          _app.account_owner = response.account_holder;
          _app.bank = response.bank;
          _app.account_number = response.account_number;
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  },
}
</script>
